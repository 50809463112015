import api from './api'

const url = 'promotions'

const findActivePromotion = () => api.get(`${url}/find-active-promotions`);



export default {
  findActivePromotion
}
