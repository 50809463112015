<template>
  <v-row class="pb-8">
    <v-col cols="12" sm="12" class="pt-0">
      <carousel />
      <v-row>
        <v-col cols="0" md="8" sm="6" lg="9" xl="10" class="pt-0">
          <session-title class="pt-8 px-10 pb-5" title="Promoções" />
        </v-col>
        <v-col class="pt-0 px-11 pb-3" align-self="center">
          <chat-ti-modal-access-vue />
        </v-col>
      </v-row>
    </v-col>
    <v-row align="center" class="px-12">
      <v-col
        v-for="(item, i) in imgs"
        :key="item.id"
        cols="12"
        md="3"
        sm="6"
        @click="() => showImg(i)"
      >
        <v-img class="white--text align-end" height="300px" width="300px" :src="item.image" />
      </v-col>
      <vue-easy-lightbox
        esc-disabled
        move-disabled
        :visible="visible"
        :imgs="imageLightbox"
        :index="index"
        @hide="handleHide"
      />
    </v-row>
  </v-row>
</template>

<script>
import SessionTitle from "@/components/SessionTitle";
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";
import Carousel from "@/components/Carousel";
import Promotions from "../../services/promotion-service";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "Promotion",
  components: {
    VueEasyLightbox,
    carousel: Carousel,
    "session-title": SessionTitle,
    ChatTiModalAccessVue
  },
  data() {
    return {
      imgs: [],
      imageLightbox: [],
      index: 0,
      visible: false
    };
  },
  async mounted() {
    this.initList();
  },
  methods: {
    async initList() {
      try {
        const data = await Promotions.findActivePromotion();
        this.imgs = data.filter((data) => data.image != null);
        data.map(urls => {
          this.imageLightbox.push(urls.image);
        });
      } catch (e) {
        this.$handleHttpError(e);
      }
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    }
  },

  
};
</script>
